import { CommonModule, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import localeIt from '@angular/common/locales/it';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { environment } from '@env/environment';
import { NgbModalModule, NgbToastModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import { PixelModule } from 'ngx-pixel';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertModalComponent } from './components/alert-modal/alert-modal.component';
import { ToastsContainer } from './components/toasts/toasts.component';
import { HttpErrorInterceptor } from './utils/http-error.interceptor';

registerLocaleData(localeIt);

let trackModules = [];

if (environment.production) {
  trackModules = [
    NgxGoogleAnalyticsModule.forRoot(
      environment.googleTrackingCode,
      null,
      'https://googletagmanager.com/gtm.js?id=' + environment.googleTrackingCode
    ),
    NgxGoogleAnalyticsRouterModule,
    PixelModule.forRoot({ enabled: environment.pixelTrackingEnabled, pixelId: environment.pixelTrackingCode }),
  ];
}

@NgModule({
  imports: [
    CommonModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    NgbToastModule,
    NgbModalModule,
    ...trackModules,
  ],
  exports: [ToastsContainer, AlertModalComponent],
  declarations: [ToastsContainer, AlertModalComponent],
  providers: [
    NgxSpinnerService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
  ],
})
export class CommonCoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CommonCoreModule) {
    if (parentModule) {
      throw new Error(`CommonCoreModule has already been loaded. Import Core modules in the AppModule only.`);
    }
  }
}
