import { Component, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirm-action',
  template: `
    <div class="modal-header border-0">
      <h4 class="modal-title" id="modal-title">{{ title }}</h4>
      <button type="button" class="btn-close" aria-describedby="modal-title" (click)="dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
      <p [innerHTML]="sanitize(message)"></p>
    </div>
    <div class="modal-footer border-0" *ngIf="type === 'confirm'">
      <button type="button" class="btn btn-outline-secondary" (click)="dismiss('cancel click')">Annulla</button>
      <button type="button" class="btn btn-secondary" (click)="confirm()">Conferma</button>
    </div>
  `,
})
export class AlertModalComponent {
  title: string;
  message: string;
  type: 'confirm' | 'info';

  constructor(
    private sanitizer: DomSanitizer,
    private modal: NgbActiveModal
  ) {}

  sanitize(html: string) {
    return this.sanitizer.sanitize(SecurityContext.HTML, html);
  }

  dismiss(reason: string) {
    this.modal.dismiss(reason);
  }

  confirm() {
    this.modal.close(true);
  }
}
