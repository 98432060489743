import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UserType } from '@common/models/auth';
import { AsideMenuInfo } from '@common/models/common';
import { Notification } from '@common/models/notifications';
import { NgbActiveOffcanvas } from '@ng-bootstrap/ng-bootstrap';

export type AsideMenuItem = {
  icon: string;
  url: string;
  label: string;
  hasSeparator?: boolean;
};

@Component({
  selector: 'app-aside-menu',
  templateUrl: './aside-menu.component.html',
  host: { id: 'navbarInPlace' },
})
export class AsideMenuComponent implements OnInit {
  @Input() isLogged: boolean;
  @Input() type: UserType;
  @Input() menuItems: AsideMenuItem[] | null;
  @Input() menuInfo: AsideMenuInfo | null;
  @Input() notifications: Notification[];
  @Output() onLogout: EventEmitter<void> = new EventEmitter();

  commonItems: AsideMenuItem[] = [
    {
      icon: 'icon-dashboard',
      label: 'Dashboard',
      url: 'dashboard',
    },
    {
      icon: 'icon-cog',
      label: 'Profilo',
      url: 'profilo',
    },
    {
      icon: 'icon-alert',
      label: 'Notifiche',
      url: 'notifiche',
      hasSeparator: true,
    },
  ];

  otherItems: AsideMenuItem[] = [];

  constructor(private offcanvas: NgbActiveOffcanvas) {}

  get isConmpany() {
    return this.type === UserType.COMPANY;
  }

  ngOnInit() {
    this.setOtherItems(this.type);
  }

  dismiss(reason: string) {
    this.offcanvas.dismiss(reason);
  }

  private setOtherItems(type: UserType) {
    const wItems: AsideMenuItem[] = [
      {
        icon: 'icon-doc',
        label: 'Documenti',
        url: 'documenti',
      },
      {
        icon: 'icon-warning',
        label: 'Annunci',
        url: 'annunci',
        hasSeparator: true,
      },
    ];

    const cItems: AsideMenuItem[] = [
      {
        icon: 'icon-doc',
        label: 'Documenti',
        url: 'documenti',
      },
      {
        icon: 'icon-doc-edit',
        label: 'Deleghe',
        url: 'deleghe',
      },
      {
        icon: 'icon-warning',
        label: 'Annunci',
        url: 'annunci',
        hasSeparator: true,
      },
      {
        icon: 'icon-bookmark',
        label: 'candidati salvati',
        url: 'candidati/preferiti',
      },
      {
        icon: 'icon-find',
        label: 'Cerca candidati',
        url: 'candidati/cerca',
      },
    ];

    const endItems: AsideMenuItem[] = [
      {
        icon: 'icon-interview',
        label: 'Colloqui',
        url: 'colloqui',
      },
      {
        icon: 'icon-calendar',
        label: 'Disponibilitá',
        url: 'disponibilita',
      },
      {
        icon: 'icon-chat',
        label: 'Chat',
        url: 'conversazioni',
        hasSeparator: true,
      },
    ];

    switch (type) {
      case 'azienda':
        this.otherItems = [].concat(cItems, endItems);
        break;

      case 'candidato':
        this.otherItems = [].concat(wItems, endItems);
        break;
    }
  }
}
