import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SelectOption } from '@common/models/common';
import { Company, CompanyBillingInfo } from '@common/models/company';
import { NotificationState } from '@common/models/notifications';
import { AuthService } from '@common/services/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { combineLatest, Observable } from 'rxjs';
import { map, tap, withLatestFrom } from 'rxjs/operators';
import { AppStorageService } from './app-storage.service';
import { BasePageService } from './base-page.service';

export enum DocumentState {
  TO_CREATE = 'TO_CREATE',
  CREATED = 'CREATED',
  ACCEPTED = 'ACCEPTED',
  FAILED = 'FAILED',
  COMPLETED = 'COMPLETED', //Deprecated
  CERTIFIED = 'CERTIFIED',
}

@Injectable()
export class CompanyService extends BasePageService<Company> {
  status = new Map([
    [DocumentState.TO_CREATE, 'In elaborazione'],
    [DocumentState.CREATED, 'Da completare'],
    [DocumentState.ACCEPTED, 'Accettata'],
    [DocumentState.FAILED, 'Annullata'],
    [DocumentState.COMPLETED, 'Da accettare'],
    [DocumentState.CERTIFIED, 'Certificato'],
  ]);

  constructor(
    protected router: Router,
    protected http: HttpClient,
    protected auth: AuthService,
    protected storage: AppStorageService,
    protected spinner: NgxSpinnerService
  ) {
    super(router, http, auth, storage, spinner);
  }

  mapStatus(state: DocumentState) {
    return this.status.get(state);
  }

  getProfile(): Observable<Company> {
    return combineLatest([
      this.get({ endpoint: `${this.clientPath}/api/v1/client` }),
      this.get({ endpoint: `${this.clientPath}/api/v1/client-data` }),
      this.get({ endpoint: `${this.clientPath}/api/v1/client-config` }),
    ]).pipe(
      map(([data, addData, config]: any) => ({ ...data, ...addData, ...config })),
      tap(profile => this.auth.setProfile(profile))
    );
  }

  getLastNotifications() {
    const params = { status: NotificationState.NEW, page: 1, size: 4 };
    return this.notifications$(params).pipe(map(resp => resp.content));
  }

  updateProfile(body: { [key: string]: number | string }) {
    return this.post({ endpoint: `${this.clientPath}/main-data`, body });
  }

  updateLocations<T>(body: { [key: string]: number | string | Array<T> }) {
    return this.put({ endpoint: `${this.clientPath}/work-locations`, body });
  }

  updateBillingInformations(body: CompanyBillingInfo) {
    return this.put({ endpoint: `${this.clientPath}/billing-information`, body });
  }

  getCCNLCodes() {
    return this.ccnlCodes$();
  }

  getAtecoCodes() {
    return this.get<SelectOption[]>({ endpoint: `${this.publicPath}/api/v1/activity` });
  }

  getAcceptedOffers() {
    return this.get<any>({ endpoint: `${this.clientPath}/api/v1/offers/accepted` });
  }

  getTasks() {
    return this.get<SelectOption[]>({ endpoint: `${this.publicPath}/api/v1/task` });
  }

  reloadProfile() {
    return this.get<any>({ endpoint: `${this.clientPath}/api/v1/client` }).pipe(
      withLatestFrom(this.profile$),
      tap(([resp, data]) => {
        this.auth.setProfile({ ...data, ...resp });
      })
    );
  }

  getWorkerProfile(idWorker) {
    return this.get<any>({ endpoint: `${this.searchPath}/api/client/v1/search-detail?id=${idWorker}` });
  }

  getVideoCVCandidate(idWorker) {
    return this.get<any>({ endpoint: `${this.documentPath}/v1/downLoad/videocvurlclient/${idWorker}` });
  }

  getInterviewFreeSlots(interviewDate) {
    return this.get<any>({ endpoint: `${this.clientPath}/interviews/zoom/get-data/${interviewDate}` });
  }

  createInterview(body) {
    return this.post<any>({ endpoint: `${this.clientPath}/interviews`, body });
  }

  createConversationWithCandidate(body) {
    return this.post<any>({ endpoint: `${this.clientPath}/conversations`, body });
  }

  updateConversationNotificationFrequency(params) {
    return this.put<any>({ endpoint: `${this.clientPath}/conversations/notification`, params });
  }

  getWorkPlaces() {
    return this.get<any>({ endpoint: `${this.clientPath}/api/v1/worker-place` });
  }

  getRiskBoards() {
    return this.get<any>({ endpoint: `${this.clientPath}/api/v1/risk-board` });
  }

  getRiskRegistry() {
    return this.get<any>({ endpoint: `${this.clientPath}/api/v1/risk-registry` });
  }

  updateRiskRegistry(body) {
    return this.post<any>({ endpoint: `${this.clientPath}/api/v1/risk-registry`, body });
  }

  getContractProfile(id) {
    return this.get<any>({ endpoint: `${this.clientPath}/v1/contract/${id}` });
  }
}
