<div class="offcanvas-header p-4">
  <div class="row mb-3 offcanvas-title">
    <ng-template #notLogged>
      <div class="col-12">
        <a class="navbar-brand me-5" routerLink="/">
          <img class="d-none d-lg-block" src="assets/images/in-place.svg" alt="InPlace" width="160" height="50" />
          <img class="d-lg-none" src="assets/images/in-place-white.svg" alt="InPlace" width="160" height="50" />
        </a>
      </div>
    </ng-template>

    <ng-container *ngIf="isLogged && menuInfo; else notLogged">
      <div class="col-4">
        <img class="rounded-circle img-fluid" src="assets/images/avatar-speak.svg" />
      </div>

      <div class="col-8 align-items-center d-flex">
        <h3>{{ menuInfo?.name }}</h3>
      </div>
      <div class="col-12">
        <p class="mb-0 small">{{ menuInfo?.description }}</p>
        <p class="small">Iscritto dal {{ menuInfo?.created | date: 'dd/MM/yyyy' }}</p>
      </div>
      <div class="col-12 d-flex">
        <small class="">Completamento profilo</small>
        <p class="mb-0 ms-auto font-anton">{{ menuInfo?.percentage }}%</p>
      </div>
      <div class="col-12">
        <ngb-progressbar type="teal" [value]="menuInfo?.percentage"></ngb-progressbar>
      </div>
    </ng-container>
  </div>
  <button type="button" class="btn-close d-lg-none" aria-label="Apri menu" (click)="dismiss('Cross click')"></button>
</div>
<div class="offcanvas-body p-4 pt-lg-0">
  <ul class="nav font-anton text-uppercase">
    <ng-container *ngIf="!isLogged">
      <li class="nav-item">
        <a class="nav-link" [routerLink]="['/', 'azienda']" (click)="dismiss('Navigation clicked')">
          <i class="icon-log-out"></i>
          <!-- <i class="icon-log-in"></i> -->
          Aziende
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [routerLink]="['/', 'candidato']" (click)="dismiss('Navigation clicked')">
          <i class="icon-log-out"></i>
          <!-- <i class="icon-user-plus"></i> -->
          Candidati
        </a>
      </li>
    </ng-container>

    <ng-container *ngIf="isLogged">
      <ng-container *ngFor="let item of commonItems">
        <li class="nav-item">
          <a
            class="nav-link"
            [routerLink]="['/', 'private', type, item.url]"
            routerLinkActive="active"
            (click)="dismiss('Navigation clicked')">
            <i [class]="item.icon"></i>
            {{ item.label }}
          </a>
        </li>
        <hr *ngIf="item.hasSeparator" />
      </ng-container>

      <ng-container *ngFor="let item of menuItems">
        <li class="nav-item">
          <a
            class="nav-link"
            [routerLink]="['/', 'private', type, item.url]"
            routerLinkActive="active"
            (click)="dismiss('Navigation clicked')">
            <i [class]="item.icon"></i>
            {{ item.label }}
          </a>
        </li>
        <hr *ngIf="item.hasSeparator" />
      </ng-container>

      <ng-container *ngFor="let item of otherItems">
        <li class="nav-item">
          <a
            class="nav-link"
            [routerLink]="['/', 'private', type, item.url]"
            routerLinkActive="active"
            (click)="dismiss('Navigation clicked')">
            <i [class]="item.icon"></i>
            {{ item.label }}
          </a>
        </li>
        <hr *ngIf="item.hasSeparator" />
      </ng-container>
    </ng-container>

    <li class="nav-item">
      <a class="nav-link" [routerLink]="['/', 'chi-siamo']" (click)="dismiss('Navigation clicked')">
        <i class="icon-log-out"></i>
        <!-- <i class="icon-log-in"></i> -->
        Chi siamo
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link" [routerLink]="['/', 'supporto']" (click)="dismiss('Navigation clicked')">
        <i class="icon-log-out"></i>
        <!-- <i class="icon-user-plus"></i> -->
        Supporto
      </a>
    </li>

    <ng-container *ngIf="!isLogged">
      <hr />
      <li class="nav-item">
        <a class="nav-link" [routerLink]="['/', 'auth', 'login']" (click)="dismiss('Navigation clicked')">
          <i class="icon-log-out"></i>
          <!-- <i class="icon-log-in"></i> -->
          Accedi
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [routerLink]="['/', 'registrazione']" (click)="dismiss('Navigation clicked')">
          <i class="icon-log-out"></i>
          <!-- <i class="icon-user-plus"></i> -->
          Registrati
        </a>
      </li>
    </ng-container>

    <ng-container *ngIf="isLogged">
      <li class="nav-item">
        <button type="button" (click)="onLogout.emit(); dismiss('Logut')" class="w-100 nav-link">
          <i class="icon-log-out"></i>
          Esci
        </button>
      </li>
    </ng-container>
  </ul>
</div>

<ng-container *ngIf="isConmpany && menuInfo?.counters">
  <div class="bg-white rounded-5 d-lg-block d-none p-4 mt-3">
    <div class="row">
      <div class="col-12 col-lg-12">
        <h3 class="mb-4">Le tue azioni</h3>
        <div class="row g-1">
          <div class="col col-lg-6 mb-3 cursor-pointer" [routerLink]="['candidati', 'preferiti']">
            <p class="display-5 lh-1 mb-0 fw-bold text-primary font-anton me-2">
              {{ menuInfo.counters.favourites }}
            </p>
            <p class="text-underline small lh-1">Canditati salvati</p>
          </div>
          <div class="col col-lg-6 mb-3 cursor-pointer" [routerLink]="['documenti', 'contratti']">
            <p class="display-5 lh-1 mb-0 fw-bold text-primary font-anton me-2">
              {{ menuInfo.counters.contracts }}
            </p>
            <p class="text-underline small lh-1">Contratti attivati</p>
          </div>
          <div class="col col-lg-6 mb-3 cursor-pointer" [routerLink]="['annunci']">
            <p class="display-5 lh-1 mb-0 fw-bold text-primary font-anton me-2">
              {{ menuInfo.counters.jobOffers }}
            </p>
            <p class="text-underline small lh-1">Annunci pubblicati</p>
          </div>
          <div class="col col-lg-6 mb-3 cursor-pointer" [routerLink]="['documenti', 'contratti']">
            <p class="display-5 lh-1 mb-0 fw-bold text-primary font-anton me-2">
              {{ menuInfo.counters.candidates }}
            </p>
            <p class="text-underline small lh-1">Lavoratori attivati</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<div class="bg-white rounded-5 d-lg-block d-none p-4 mt-3">
  <h3 class="mb-4">
    Notifiche
    <a
      class="ms-auto btn-link badge fs-6 font-anton text-primary right"
      [routerLink]="['/', 'private', type, 'notifiche']">
      LEGGI TUTTE
    </a>
  </h3>
  <div class="toast-container position-static m-0 row justify-content-between">
    <ng-container *ngFor="let notification of notifications">
      <div class="toast show" role="alert" aria-live="assertive" aria-atomic="true" data-bs-autohide="false">
        <div class="toast-header">
          <span class="badge bg-red rounded-pill p-2 me-2">
            <span class="visually-hidden">Alert!</span>
          </span>

          <small>{{ notification.sendDate | date: 'dd/MM/yyyy' }}</small>
          <!-- <button type="button" class="ms-auto btn-close" data-bs-dismiss="toast" aria-label="Close"></button> -->
        </div>
        <div class="toast-body pt-0">
          <strong class="me-auto">{{ notification.notifyTitle }}</strong>
        </div>
      </div>
    </ng-container>
  </div>
</div>
