import { Injectable } from '@angular/core';
import { AppUser, ReferralData } from '@common/models/auth';

export enum StorageKeys {
  ACCESS_TOKEN = 'ip_access_token',
  REFRESH_TOKEN = 'ip_refresh_token',
  REGISTER_INFO = 'ip_register_info',
  USER_ROLES = 'ip_user_roles',
  APP_USER = 'ip_app_user',
  REFERRAL_DATA = 'ip_referral_data',
}

type Storage = Window['localStorage'] | Window['sessionStorage'];

type StoreType = 'local' | 'session';

type GetItemParams = {
  key: string;
  where: StoreType;
};

type SetItemParams = GetItemParams & {
  value: any;
};

@Injectable({ providedIn: 'root' })
export class AppStorageService {
  private storage: Storage;
  private session: Storage;

  constructor() {
    this.storage = window.localStorage;
    this.session = window.sessionStorage;
  }

  setAccessToken(value: string) {
    return this.setItem({ key: StorageKeys.ACCESS_TOKEN, value, where: 'local' });
  }

  getAccessToken() {
    return this.getItem({ key: StorageKeys.ACCESS_TOKEN, where: 'local' });
  }

  setRefreshToken(value: string) {
    return this.setItem({ key: StorageKeys.REFRESH_TOKEN, value, where: 'local' });
  }

  getRefreshToken() {
    return this.getItem({ key: StorageKeys.REFRESH_TOKEN, where: 'local' });
  }

  setRegisterInfo(value: string) {
    return this.setItem({ key: StorageKeys.REGISTER_INFO, value, where: 'local' });
  }

  getRegisterInfo() {
    return this.getItem({ key: StorageKeys.REGISTER_INFO, where: 'local' });
  }

  setAppUser(value: AppUser) {
    return this.setItem({ key: StorageKeys.APP_USER, value, where: 'local' });
  }

  getAppUser() {
    return this.getItem({ key: StorageKeys.APP_USER, where: 'local' });
  }

  setReferralData(value: ReferralData) {
    return this.setItem({ key: StorageKeys.REFERRAL_DATA, value, where: 'session' });
  }

  getReferralData() {
    return this.getItem({ key: StorageKeys.REFERRAL_DATA, where: 'session' });
  }

  setAnnouncementId(value: number) {
    return this.setItem({ key: 'announcementId', value, where: 'session' });
  }

  getAnnouncementId() {
    return this.getItem({ key: 'announcementId', where: 'session' });
  }

  setCandidateId(value: number) {
    return this.setItem({ key: 'candidateId', value, where: 'session' });
  }

  getCandidateId() {
    return this.getItem({ key: 'candidateId', where: 'session' });
  }

  clearLocal() {
    this.clear('local');
  }

  clearSession() {
    this.clear('session');
  }

  removeLocalItem(key: string) {
    this.removeItem(key, 'local');
  }

  removeSessionItem(key: string) {
    this.removeItem(key, 'session');
  }

  private setItem({ key, value, where }: SetItemParams) {
    try {
      if (typeof value !== 'string') {
        value = JSON.stringify(value);
      }

      if (where === 'local') {
        this.storage.setItem(key, value);
      } else if (where === 'session') {
        this.session.setItem(key, value);
      }
    } catch (err) {
      console.error(err);
    }
  }

  private getItem({ key, where }: GetItemParams): any {
    try {
      let value = null;

      if (where === 'local') {
        value = this.storage.getItem(key);
      } else if (where === 'session') {
        value = this.session.getItem(key);
      }

      if (!value) {
        return null;
      }

      if (this.isJson(value)) {
        return JSON.parse(value);
      }

      return value;
    } catch (err) {
      console.error(err);
      return null;
    }
  }

  private isJson(value: string) {
    try {
      JSON.parse(value);
    } catch (e) {
      return false;
    }
    return true;
  }

  private removeItem(key: string, where: StoreType) {
    if (where === 'local') {
      this.storage.removeItem(key);
    } else if (where === 'session') {
      this.session.removeItem(key);
    }
  }

  private clear(type: StoreType) {
    if (type === 'local') {
      this.storage.clear();
    } else if (type === 'session') {
      this.session.clear();
    }
  }
}
