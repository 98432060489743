import { Component, OnDestroy, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AsideMenuComponent } from '@common/components/aside-menu/aside-menu.component';
import { getRolePath, UserType } from '@common/models/auth';
import { AsideMenuInfo, HeaderUser } from '@common/models/common';
import { HeadService } from '@common/services/head.service';
import { PublicService } from '@common/services/pubblic.service';
import { CommonSharedModule } from '@common/shared.module';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { combineLatest, Subscription } from 'rxjs';

@Component({
  selector: 'app-not-found',
  template: `
    <app-header homeUrl="/" [user]="user" (openMenu)="openMenu()"></app-header>

    <main class="py-4 not-found">
      <div class="container text-center">
        <div class="row">
          <div class="col-12">
            <h1>Error 404 not found</h1>
            <p class="lead">Nessuna pagina disponibile</p>
          </div>
          <div class="col-12">
            <h3>Per tornare su inplace...</h3>
            <a class="btn btn-link px-2" [routerLink]="['/']">Torna alla pagina iniziale</a>
          </div>
        </div>
      </div>
    </main>

    <app-footer></app-footer>
  `,
  styles: [
    `
      .not-found {
        height: calc(100vh - 409px);
      }
    `,
  ],
  standalone: true,
  imports: [RouterModule, CommonSharedModule],
  providers: [PublicService],
})
export class NotFoundComponent implements OnInit, OnDestroy {
  user: HeaderUser;
  menuInfo: AsideMenuInfo;
  subs = new Subscription();

  title = 'Error 404 not found';
  description = 'Erroro il contento riechiesto non é stato torvato.';

  constructor(
    private offcanvas: NgbOffcanvas,
    private head: HeadService,
    private pageService: PublicService
  ) {
    this.head.setTitle(this.title);
    this.head.setDesctiption(this.description);
    this.head.addJsonDl({
      title: this.title,
      description: this.description,
    });
  }

  ngOnInit() {
    this.subs.add(
      combineLatest([this.pageService.user$, this.pageService.profile$]).subscribe(([user, profile]) => {
        this.user = user && {
          name: user.username,
          impersonated: !!user.impersonator,
          path: getRolePath(user.roles[0] as any),
        };

        if (this.user && this.user.path === UserType.COMPANY) {
          this.menuInfo = profile && {
            name: profile.ragioneSociale,
            description: profile.codiceCcnlPrincipale?.des,
            percentage: profile.completitionPercentage,
            created: profile.created,
            counters: {
              favourites: profile?.favouriteCandidatesCount,
              contracts: profile?.contractCount,
              jobOffers: profile?.jobOfferCount,
              candidates: profile?.candidateCount,
            },
          };
        } else if (this.user && this.user.path === UserType.WORKER) {
          this.menuInfo = profile && {
            name: profile.nome,
            description: profile.aiSummary?.jobProfile,
            percentage: profile.completitionPercentage,
            created: profile.created,
          };
        }
      })
    );
  }

  openMenu() {
    const config = { panelClass: 'aside-nav-menu', ariaLabelledBy: 'offcanvas-basic-title' };
    const component = this.offcanvas.open(AsideMenuComponent, config).componentInstance as AsideMenuComponent;

    component.isLogged = !!this.user;
    component.type = this.user?.path;
    component.menuInfo = this.menuInfo;

    this.subs.add(
      component.onLogout.subscribe(() => {
        this.logout();
      })
    );
  }

  ngOnDestroy(): void {
    this.subs?.unsubscribe();
  }

  private logout() {
    this.pageService.logout();
  }
}
