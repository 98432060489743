import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { AppStorageService } from '@common/services/app-storage.service';
import { MappingsService } from '@common/services/mappings.service';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-root',
  template: `
    <router-outlet></router-outlet>
    <app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
    <ngx-spinner type="ball-scale-multiple"></ngx-spinner>
  `,
})
export class AppComponent implements OnInit, OnDestroy {
  termsUlr = environment.privacy.term;
  policyUlr = environment.privacy.policy;
  coockieUlr = environment.privacy.cookie;

  subs = new Subscription();

  constructor(
    private route: ActivatedRoute,
    private storage: AppStorageService,
    private mappings: MappingsService
  ) {
    console.log('_____________________ SGB-WEB VERSION 2 ____________________');
  }

  ngOnInit() {
    this.mappings.getMappings().subscribe();

    this.subs.add(
      this.route.queryParamMap
        .pipe(
          tap(params => {
            if (params.has('utm_campaign')) {
              this.saveRefferalData(params);
            }

            if (params.has('privacy')) {
              switch (params.get('privacy')) {
                case 'terms':
                  this.navigateTo(this.termsUlr);
                  break;
                case 'general':
                  this.navigateTo(this.policyUlr);
                  break;
                case 'cookie':
                  this.navigateTo(this.coockieUlr);
                  break;
              }
            }
          })
        )
        .subscribe()
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  private navigateTo(url) {
    window.open(url, '_balnk');
  }

  private saveRefferalData(params: ParamMap) {
    const data = {
      campaign_type: params.get('utm_source'),
      origin_domain: params.get('utm_medium'),
      campaign_id: params.get('utm_campaign'),
      campaign_name: params.get('utm_term'),
      partner_id: params.get('utm_partner'),
    };

    this.storage.setReferralData(data);
  }
}
