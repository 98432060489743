export interface Notification {
  channel: ChannelType;
  iconType: string;
  id: number;
  notifyText: string;
  notifyTitle: string;
  sendDate: string;
  userUuid: string;
  uuid: string;
  view: ViewType;
}

export enum ChannelType {
  EMAIL = 'EMAIL',
  PUSH = 'PUSH',
}

export enum ViewType {
  READ = 'READ',
  NOT_READ = 'NOT_READ',
}

export enum NotificationState {
  ALL = 'ALL',
  NEW = 'NOT_READ',
  OLD = 'READ',
}

export interface NotificationsList {
  content: Notification[];
  empty: boolean;
  first: boolean;
  last: boolean;
  number: number;
  numberOfElements: number;
  pageable: {
    offset: number;
    pageNumber: number;
    pageSize: number;
    paged: boolean;
    sort: {
      empty: boolean;
      sorted: boolean;
      unsorted: boolean;
    };
    unpaged: boolean;
  };
  size: number;
  sort: {
    empty: boolean;
    sorted: boolean;
    unsorted: boolean;
  };
  totalElements: number;
  totalPages: number;
}
